import { IncomingMessage } from 'http';
import { assertNonEmptyString } from '@sortlist-frontend/utils/src/assertions';
import { HttpBadRequest } from '@sortlist-frontend/utils/src/errors';
import { isNonEmptyString } from '@sortlist-frontend/utils/src/typeguards';

import { DomainInfo } from '_backend/integration/domain-info';

type ExtractLocaleParams = {
  domainInfo: DomainInfo;
  origin: string;
  path: string | undefined;
};

const extractLocale = (
  params: ExtractLocaleParams,
): {
  locale: string;
  redirectLocale?: boolean;
  redirectNoLocale?: boolean;
} => {
  const { domainInfo, origin, path } = params;
  const locales = domainInfo.getLocales();

  if (!locales) {
    throw new HttpBadRequest({ message: 'Domain configuration issue!', url: `${origin}${path}` });
  }

  const mainLocale = domainInfo.getMainLocale();
  const pathSteps = path?.split('/');

  const fallback = {
    locale: mainLocale ?? locales[0],
    redirectNoLocale: false,
    redirectLocale: !mainLocale,
  };

  if (pathSteps && pathSteps.length > 1) {
    const locale = pathSteps[1];

    // The first path step is one of the locales we're accepting for
    // this specific domain, then we don't need to redirect, except if
    // it's the mainLocale of that domain, so we just need to remove it
    if (locales?.includes(locale)) {
      return {
        locale,
        redirectLocale: false,
        redirectNoLocale: locale === mainLocale,
      };
    }

    // Nothing in the query params? Just take the mainLocal, and if there
    // isn't one, just take the first accepted locale for that domain.
    return fallback;
  }

  throw new HttpBadRequest({ message: 'Domain configuration issue!', url: `${origin}${path}` });
};

export const extractOrigin = (req: IncomingMessage, defaultHost = 'www.sortlist.local') => {
  let host = req.headers.host || defaultHost;
  let protocol = /^localhost(:\d+)?$/.test(host) ? 'http' : 'https';

  const forwardedHost = req.headers['x-forwarded-host'];
  const forwardedProtocol = req.headers['x-forwarded-proto'];

  if (forwardedHost && typeof forwardedHost === 'string') {
    host = forwardedHost;
  }

  if (forwardedProtocol && typeof forwardedProtocol === 'string') {
    protocol = forwardedProtocol;
  }

  protocol = protocol.includes('https') ? 'https' : 'http';

  return protocol + '://' + host;
};
export const extractDomainRelevantInfo = (origin: string, path: string, qs: string) => {
  const domainInfo = DomainInfo.getFromOrigin(origin);
  const query = isNonEmptyString(qs) ? `?${qs}` : '';

  if (!domainInfo) throw new HttpBadRequest({ message: 'Not an accepted domain' });

  const { locale, redirectLocale, redirectNoLocale } = extractLocale({
    domainInfo,
    origin,
    path,
  });

  return {
    // canonical is the current URL without query params.
    // It's used to say to google what is the main page to
    // index and that same page with query params are not
    // separated pages.
    canonical: `${origin}${path === '/' ? '' : path}`,
    // We'll need the query in combination with the canonical
    // to build the alternate links, which are used to indicate
    // to Google what are the exact same pages in other languages.
    // Different parts of the alternate URLs are translated so
    // we'll get them from database and simply add the query.
    query,
    origin,
    locale,
    redirectLocale,
    redirectNoLocale,
    iso31661: domainInfo.getIso31661(),
  };
};

export const extractDomainRelevantInfoFromHeaders = (req: IncomingMessage) => {
  const origin = req.headers['sortlist-origin'];
  const locale = req.headers['sortlist-locale'];
  const canonical = req.headers['sortlist-canonical'];

  assertNonEmptyString(origin, new HttpBadRequest({ message: 'Missing [origin] parameter' }));
  assertNonEmptyString(locale, new HttpBadRequest({ message: 'Missing [locale] parameter' }));
  assertNonEmptyString(canonical, new HttpBadRequest({ message: 'Missing [canonical] parameter' }));

  return { origin, locale, canonical };
};
