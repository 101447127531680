import { isArray, isBrowser, merge, pickBy, useLocalStorage } from '@sortlist-frontend/utils';
import { getCookie } from 'cookies-next';
import { type NextRouter } from 'next/router';

import { config } from '../defaults';
import {
  AB_TESTS_COOKIE_ASSIGNATOR,
  AB_TESTS_COOKIE_NAME,
  AB_TESTS_COOKIE_SEPARATOR,
  FEATURE_FLAGS_OVERRIDE_ASSIGNATOR,
  FEATURE_FLAGS_OVERRIDE_PARAM,
  FEATURE_FLAGS_OVERRIDE_SEPARATOR,
  GB_COOKIE_NAME,
} from './constants';
import {
  Domain,
  EnabledForConfig,
  Environment,
  FeatureFlagSetup,
  FeatureFlagValue,
  FeatureFlagWithExperiment,
  isFeatureFlagWithExperiment,
  MergedFeatureToggles,
} from './types';
import { isRouteActivatedForExperiment, marketToDomains } from './utils';

export function useExtractFeatureFlagOverrides(
  query: NextRouter['query'],
  requestUrl?: string,
  abTestsCookieValue?: string,
): FeatureFlagSetup {
  const params = query[FEATURE_FLAGS_OVERRIDE_PARAM];

  const configOverrideFromParams = extractConfigFromParams(params);
  const configOverrideFromLocalStorage = extractConfigFromLocalStorage();
  const configOverrideFromEnvironment = determineConfigFromEnvironment();
  const configOverrideFromMarket = determineConfigFromMarket();

  const experimentsFromCookie = abTestsCookieValue ?? getCookie(AB_TESTS_COOKIE_NAME)?.toString();
  const gbUuid = getCookie(GB_COOKIE_NAME)?.toString();

  const configOverrideFromCookies = useConfigOverrideFromCookies(requestUrl, { experimentsFromCookie, gbUuid });

  const configOverride = merge(
    {},
    configOverrideFromMarket,
    configOverrideFromEnvironment,
    configOverrideFromCookies,
    configOverrideFromLocalStorage,
    configOverrideFromParams,
  );
  return { configOverride };
}

function extractConfigFromParams(params?: string | string[]) {
  if (params == null || isArray(params)) {
    return {};
  }

  return stringToConfigObject(params);
}

function extractConfigFromLocalStorage() {
  const [value] = useLocalStorage(FEATURE_FLAGS_OVERRIDE_PARAM, undefined, { deserializer: (x) => x });

  if (value == null) {
    return {};
  }

  return stringToConfigObject(value);
}

export function useConfigOverrideFromCookies(
  requestUrl: string | undefined,
  cookies: { experimentsFromCookie?: string | null; gbUuid?: string | null },
) {
  const { experimentsFromCookie, gbUuid } = cookies;
  if (experimentsFromCookie == null) {
    return;
  }
  const savedExperiments = experimentsFromCookie?.toString().split(AB_TESTS_COOKIE_SEPARATOR);

  const configOverride: Record<string, Partial<FeatureFlagWithExperiment>> = {};

  for (const experiment of savedExperiments) {
    const [experimentKey, variant] = experiment.split(AB_TESTS_COOKIE_ASSIGNATOR);

    const linkedFeatureFlag = Object.entries(config).find(([_, flagConfig]) => {
      if (isFeatureFlagWithExperiment(flagConfig)) {
        return flagConfig.experiment.key === experimentKey;
      }
      return false;
    })?.[0];

    if (linkedFeatureFlag == null) {
      console.error(`Current experiment in cookies ${experimentKey} is not assigned to any configured feature flag`);
      continue;
    }
    const experimentConfig = config[linkedFeatureFlag as keyof typeof config] as FeatureFlagWithExperiment;

    if (requestUrl != null && !isRouteActivatedForExperiment(experimentConfig.experiment, requestUrl)) continue;

    const override: Partial<FeatureFlagWithExperiment> = {
      defaultValue: variant as FeatureFlagValue,
      experiment: {
        key: experimentKey,
        isRunning: true,
        domains: experimentConfig?.experiment?.domains,
        ...('pathnames' in experimentConfig.experiment
          ? { pathnames: experimentConfig?.experiment?.pathnames }
          : { excludedPathnames: experimentConfig?.experiment?.excludedPathnames }),
        assignedUniqueId: gbUuid?.toString(),
      },
    };

    configOverride[linkedFeatureFlag] = override;
  }

  return configOverride as Partial<MergedFeatureToggles>;
}

function stringToConfigObject(value: string): Partial<MergedFeatureToggles> {
  return value.split(FEATURE_FLAGS_OVERRIDE_SEPARATOR).reduce((memo, config: string) => {
    // [0] is the feature flag name
    // [1] is the defaultValue value
    const chunks = config.split(FEATURE_FLAGS_OVERRIDE_ASSIGNATOR);
    const key = chunks[0];
    const rawValue = chunks[1];
    const value = rawValue === 'true' ? true : rawValue === 'false' ? false : rawValue;

    return {
      ...memo,
      [key]: {
        defaultValue: value,
      },
    };
  }, {});
}

export function determineConfigFromEnvironment() {
  if (!isBrowser()) {
    return {};
  }

  const environment = getEnvironmentFromHostname(window.location.hostname);

  if (environment == null) {
    return {};
  }

  const environments = isArray(environment) ? environment : [environment];
  const flagsWithEnvironmentConfig = pickBy(config, (value) => 'enabledForEnvironments' in value);

  return Object.entries(flagsWithEnvironmentConfig).reduce((memo, [key, value]) => {
    // If there is a market config, we don't want to override it
    if ((value as EnabledForConfig).enabledForMarkets != null) return memo;

    if ((value as EnabledForConfig).enabledForEnvironments?.some((enabledEnv) => environments.includes(enabledEnv))) {
      return {
        ...memo,
        [key]: {
          defaultValue: true,
        },
      };
    }
    return memo;
  }, {});
}

function getEnvironmentFromHostname(hostname: string): Environment | Environment[] | undefined {
  if (hostname === 'localhost') {
    return 'development';
  }

  if (hostname.endsWith('sandbox.sortlist.cloud')) {
    const sandboxId = hostname.match(/\d+/)?.[0];
    if (sandboxId == null) {
      return undefined;
    }
    return [`sandbox-${Number(sandboxId)}`, 'sandbox'];
  }

  if (hostname.includes('sortlist-test.')) {
    return 'staging';
  }

  if (hostname.includes('sortlist.')) {
    // This shouldn't log an error, but for production we skip this check, and instead
    // should enable the flag by default
    return 'production';
  }

  console.error(`${hostname} is not a supported environment`);
  return undefined;
}

function determineConfigFromMarket() {
  if (!isBrowser()) {
    return {};
  }

  const flagsWithMarketConfig = pickBy(config, (value) => 'enabledForMarkets' in value);

  return Object.entries(flagsWithMarketConfig).reduce((memo, [key, value]) => {
    const enabledEnvironments = (value as EnabledForConfig).enabledForEnvironments;
    const enabledMarkets = (value as EnabledForConfig).enabledForMarkets;
    const environment = getEnvironmentFromHostname(window.location.hostname);
    const environments = isArray(environment) ? environment : [environment];

    const enabledDomains = enabledMarkets?.map(marketToDomains).flat();
    const isEnabledForMarket =
      enabledMarkets == null ? true : enabledDomains?.includes(window.location.hostname as Domain);

    const validConfig = {
      ...memo,
      [key]: {
        defaultValue: true,
      },
    };

    //If there is no environment config
    if (enabledEnvironments == null) {
      // If we are in development or sandbox, we return false by default (could be finetuned in the future)
      if (['development', 'sandbox'].some((env) => environments.includes(env as Environment))) {
        return memo;
      }

      return isEnabledForMarket ? validConfig : memo;
    }

    // If we also have an environment config and we are in one of the enabled environments
    if (enabledEnvironments.some((env) => environments.includes(env))) {
      // We care about the market only in staging
      if (environments.includes('staging')) {
        return isEnabledForMarket ? validConfig : memo;
      }

      return validConfig;
    }

    return memo;
  }, {});
}
