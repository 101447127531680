import wildcardMatch from 'wildcard-match';

import { Domain, Experiment, Market } from './types';

export const STAGING_GLOBAL_TLD: Domain = 'www.sortlist-test.com';
export const STAGING_ES_TLD: Domain = 'www-es.sortlist-test.com';
export const STAGING_NL_TLD: Domain = 'www-nl.sortlist-test.com';
export const STAGING_DE_TLD: Domain = 'www-de.sortlist-test.com';
export const STAGING_IT_TLD: Domain = 'www-it.sortlist-test.com';
export const STAGING_CH_TLD: Domain = 'www-ch.sortlist-test.com';
export const STAGING_FR_TLD: Domain = 'www-fr.sortlist-test.com';
export const STAGING_CA_TLD: Domain = 'www-ca.sortlist-test.com';
export const STAGING_US_TLD: Domain = 'www-us.sortlist-test.com';
export const STAGING_UK_TLD: Domain = 'www-uk.sortlist-test.com';
export const STAGING_BE_TLD: Domain = 'www.sortlist-test.be';

export const PROD_GLOBAL_TLD: Domain = 'www.sortlist.com';
export const PROD_FR_TLD: Domain = 'www.sortlist.fr';
export const PROD_ES_TLD: Domain = 'www.sortlist.es';
export const PROD_NL_TLD: Domain = 'www.sortlist.nl';
export const PROD_DE_TLD: Domain = 'www.sortlist.de';
export const PROD_IT_TLD: Domain = 'www.sortlist.it';
export const PROD_CH_TLD: Domain = 'www.sortlist.ch';
export const PROD_BE_TLD: Domain = 'www.sortlist.be';
export const PROD_CA_TLD: Domain = 'www.sortlist.ca';
export const PROD_US_TLD: Domain = 'www.sortlist.us';
export const PROD_UK_TLD: Domain = 'www.sortlist.co.uk';

export function marketToDomains(market: Market): Domain[] {
  const domains: Domain[] = ['localhost'];
  switch (market) {
    case 'fr':
      domains.push(...[STAGING_FR_TLD, PROD_FR_TLD]);
      break;
    case 'es':
      domains.push(...[STAGING_ES_TLD, PROD_ES_TLD]);
      break;
    case 'nl':
      domains.push(...[STAGING_NL_TLD, PROD_NL_TLD]);
      break;
    case 'de':
      domains.push(...[STAGING_DE_TLD, PROD_DE_TLD]);
      break;
    case 'it':
      domains.push(...[STAGING_IT_TLD, PROD_IT_TLD]);
      break;
    case 'ch':
      domains.push(...[STAGING_CH_TLD, PROD_CH_TLD]);
      break;
    case 'ca':
      domains.push(...[STAGING_CA_TLD, PROD_CA_TLD]);
      break;
    case 'us':
      domains.push(...[STAGING_US_TLD, PROD_US_TLD]);
      break;
    case 'uk':
      domains.push(...[STAGING_UK_TLD, PROD_UK_TLD]);
      break;
    case 'be':
      domains.push(...[STAGING_BE_TLD, PROD_BE_TLD]);
      break;
    default:
      domains.push(...[STAGING_GLOBAL_TLD, PROD_GLOBAL_TLD]);
  }
  return domains;
}

const isIncluded = (config: string[], test: string): boolean => {
  return config.some((pattern) => wildcardMatch(pattern)(test));
};

export const isRouteActivatedForExperiment = (experiment: Experiment, requestUrl: string) => {
  const url = new URL(requestUrl);
  const { hostname, pathname } = url;

  if (!isIncluded(experiment.domains as string[], hostname)) return false;

  if ('excludedPathnames' in experiment) return !isIncluded(experiment.excludedPathnames as string[], pathname);

  return isIncluded(experiment.pathnames as string[], pathname);
};

const addDays = (date: string, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getExperienceLevelExperimentStatus = (startDate: string, days = 1, today = new Date()): boolean => {
  const experimentStartDate = new Date(startDate);
  const experimentEndDate = addDays(startDate, days);

  return today >= experimentStartDate && today <= experimentEndDate;
};

export const isTempExperimentActive: boolean = getExperienceLevelExperimentStatus('2024-12-06');
