import { isBrowser } from '@sortlist-frontend/utils';
import { getCookie } from 'cookies-next';
import type { GetServerSidePropsContext } from 'next';

import {
  AB_TESTS_COOKIE_ASSIGNATOR,
  AB_TESTS_COOKIE_NAME,
  AB_TESTS_COOKIE_SEPARATOR,
  GB_COOKIE_NAME,
} from './constants';
import {
  AvailableFeatureNames,
  Experiment,
  FeatureFlagSetup,
  FeatureFlagValue,
  FeatureFlagWithExperiment,
} from './types';

export function extractAbTestsFromCookies(req: GetServerSidePropsContext['req']) {
  // Only do this on the server as on the browser we check the cookies in the storage directly
  if (isBrowser() || req == null) {
    return;
  }

  return req.cookies[AB_TESTS_COOKIE_NAME];
}

export function extractAbTestsFromCookiesClient(featureFlagsConfig: FeatureFlagSetup) {
  const config = featureFlagsConfig.configOverride;
  const abTestsCookieValue = getCookie(AB_TESTS_COOKIE_NAME)?.toString();
  const gbuuid = getCookie(GB_COOKIE_NAME)?.toString();

  const experiments = abTestsCookieValue?.split(AB_TESTS_COOKIE_SEPARATOR).reduce(
    (experiments, experiment) => {
      const [experimentName, experimentVariant] = experiment.split(AB_TESTS_COOKIE_ASSIGNATOR);

      for (const flag in config) {
        const featureFlag = config[flag as AvailableFeatureNames] as FeatureFlagWithExperiment;
        if (featureFlag?.experiment?.key === experimentName) {
          experiments[experimentName] = experimentVariant as FeatureFlagValue;
        }
      }

      return experiments;
    },
    {} as Record<Experiment['key'], FeatureFlagValue>,
  );

  return {
    gbuuid,
    experiments,
  };
}
