import {
  experienceLevelDomains,
  experienceLevelExcludedPathnames,
  LOCALHOST,
  positiveReviewDomains,
  positiveReviewPathnames,
  reduceDisplayInformationDomains,
  reduceDisplayInformationExcludedPathnames,
  STAGING_GTLD,
} from './src/config/public-app-pathnames';
import { FeatureToggleConfig } from './src/types';
import { isTempExperimentActive } from './src/utils';

export const config = {
  // Here as a default / example
  'my-feature-flag': {
    defaultValue: false,
    variants: ['control', 'variation-1', 'variation-2'],
    experiment: {
      key: 'test-experiment',
      domains: [STAGING_GTLD, LOCALHOST],
      pathnames: ['**'],
    },
  },
  'positive-review': {
    defaultValue: false,
    variants: ['control', 'variation-review'],
    experiment: {
      key: 'positive-review',
      domains: positiveReviewDomains,
      pathnames: positiveReviewPathnames,
    },
  },
  'experience-level': {
    defaultValue: false,
    variants: ['control', 'variation-donut', 'variation-bar'],
    experiment: {
      key: 'experience-level',
      domains: experienceLevelDomains,
      excludedPathnames: experienceLevelExcludedPathnames,
    },
  },
  'experience-level-donut': {
    defaultValue: isTempExperimentActive,
  },
  'search-topics': {
    defaultValue: false,
  },
  'reduce-display-information': {
    defaultValue: false,
    variants: ['control', 'variation-removal'],
    experiment: {
      key: 'reduce-display-information',
      domains: reduceDisplayInformationDomains,
      excludedPathnames: reduceDisplayInformationExcludedPathnames,
    },
  },
  calcom_scheduling: {
    defaultValue: false,
    enabledForEnvironments: ['development'],
  },
  new_direct_briefing: {
    defaultValue: true,
  },
  'briefing-ai': {
    defaultValue: false,
    // temporarily disabling this feature until everything's fixed
    // enabledForMarkets: ['uk', 'global'],
  },
  shortlist: {
    defaultValue: true,
  },
} as const satisfies Record<string, FeatureToggleConfig>;
